.sub-container {
    width: 760px;
}

.container-title {
    font-family: 'Proxima Nova Bold';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 10px;
}

.sub-menu-items {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 8px 0px;
    gap: 8px;
}

.sub-menu-items-active {
    color: #018D8A;
    border-left: 2px solid #018D8A;
}