@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Proxima Nova";
  src: url("/fonts/proximanova-regular.otf");
}

@font-face {
  font-family: "Proxima Nova Bold";
  src: url("/fonts/proximanova-bold.otf");
}

@font-face {
  font-family: "Alternate Gothic No2 D";
  src: url("/fonts/AlternateGotNo2D.otf");
}

@font-face {
  font-family: "Alternate Gothic No2 D Trimmed";
  src: url("/fonts/AlternateGotNo2DTrimmed.woff");
}

:root {
  --zoom: 0.8;
}

#__next {
  zoom: calc(100% * var(--zoom));
}

.szh-menu__item {
  font-size: 12px !important;
}

.proxima-regular {
  font-family: "Proxima Nova";
}

.proxima-bold {
  font-family: "Proxima Nova Bold";
}

.alternate-gothic {
  font-family: "Alternate Gothic No2 D";
}

.alternate-gothic-trimmed {
  font-family: "Alternate Gothic No2 D Trimmed";
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.PhoneInputInput {
  border: none;
}

.without-ring {
  @apply focus:ring-0 focus:ring-offset-0;
}

[type="tel"]:focus-visible {
  @apply focus:ring-0 focus:ring-offset-0;
}

input[type="text"]:focus {
  box-shadow: none;
}

input:checked ~ .dot {
  transform: translateX(100%);
}

.ck.ck-content ul,
.ck.ck-content ol {
  list-style: revert;
  padding-left: revert;
}

.ck.ck-content a {
  all: revert;
}

.ck.ck-content h2,
.ck.ck-content h3,
.ck.ck-content h4 {
  font-weight: revert;
}

.ck-powered-by {
  display: none;
}

:root {
  --ck-color-focus-border: #018d8a !important;
  --ck-color-button-on-color: #018d8a !important;
  --ck-color-button-on-background: #80c6c4 !important;
  --ck-color-button-on-hover-background: #80c6c4 !important;
}

.mce-edit-focus {
  outline: 2px dashed #018d8a;
  border-radius: 1px;
}

.tox-tinymce-aux {
  z-index: 10 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.drag-handle {
  position: fixed;
  opacity: 1;
  transition: opacity ease-in 0.2s;
  border-radius: 0.25rem;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 10' style='fill: rgba(0, 0, 0, 0.5)'%3E%3Cpath d='M3,2 C2.44771525,2 2,1.55228475 2,1 C2,0.44771525 2.44771525,0 3,0 C3.55228475,0 4,0.44771525 4,1 C4,1.55228475 3.55228475,2 3,2 Z M3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 C3.55228475,4 4,4.44771525 4,5 C4,5.55228475 3.55228475,6 3,6 Z M3,10 C2.44771525,10 2,9.55228475 2,9 C2,8.44771525 2.44771525,8 3,8 C3.55228475,8 4,8.44771525 4,9 C4,9.55228475 3.55228475,10 3,10 Z M7,2 C6.44771525,2 6,1.55228475 6,1 C6,0.44771525 6.44771525,0 7,0 C7.55228475,0 8,0.44771525 8,1 C8,1.55228475 7.55228475,2 7,2 Z M7,6 C6.44771525,6 6,5.55228475 6,5 C6,4.44771525 6.44771525,4 7,4 C7.55228475,4 8,4.44771525 8,5 C8,5.55228475 7.55228475,6 7,6 Z M7,10 C6.44771525,10 6,9.55228475 6,9 C6,8.44771525 6.44771525,8 7,8 C7.55228475,8 8,8.44771525 8,9 C8,9.55228475 7.55228475,10 7,10 Z'%3E%3C/path%3E%3C/svg%3E");
  background-size: calc(0.5em + 0.375rem) calc(0.5em + 0.375rem);
  background-repeat: no-repeat;
  background-position: center;
  width: 1.2rem;
  height: 1.5rem;
  z-index: 50;
  cursor: grab;
}
.drag-handle:hover {
  background-color: var(--novel-stone-100);
  transition: background-color 0.2s;
}
.drag-handle:active {
  background-color: var(--novel-stone-200);
  transition: background-color 0.2s;
  cursor: grabbing;
}
.drag-handle.hide {
  opacity: 0;
  pointer-events: none;
}
@media screen and (max-width: 600px) {
  .drag-handle {
    display: none;
    pointer-events: none;
  }
}

.ProseMirror .is-editor-empty:first-child::before {
  content: attr(data-placeholder);
  float: left;
  color: #7c7c7c;
  pointer-events: none;
  height: 0;
}
.ProseMirror .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #7c7c7c;
  pointer-events: none;
  height: 0;
}
