.login-container {
    width: calc(100vw/var(--zoom));
    height: calc(100vh/var(--zoom));
    background: #FFFFFF;
    display: flex;
    flex: 1 1 100%;
    align-items: stretch;
    justify-content: space-between;
}

.login-left {
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
}

.login-left-content {
    width: 48vw;
}

.login-right {
    width: 52%;
    min-width: 320px;
    height: calc(100vh/var(--zoom));
}

.login-right-content {
    margin: 192px 120px;
    height: 456px;
    align-items: flex-start;
}

.login-logo {
    height: 10px;
}

.login-welcome {
    font-family: "Alternate Gothic No2 D", "Times New Roman", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    margin-top: 28px;
    color: #252729;
}

.login-welcome-text {
    font-family: "Proxima Nova", Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
    color: #636161;
}

.login-input-group {
    margin-top: 48px;

    /* Input */
    box-sizing: border-box;

    /* Auto layout */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    padding: 8px 8px 8px 16px;
    
    /* Background/Light */
    background: #FFFFFF;
    
    /* Grey/Borders */
    border: 1px solid #E9E8E8;
    border-radius: 8px;
}

.login-input-group-label {
    /* Footnote • 12pt/bold */
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* Text/Secondary */
    color: #636161;
}

.login-input-group-input {
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;

    /* identical to box height, or 150% */
    display: flex;
    align-items: center;

    /* Text/Tertiary */
    color: #A6A5A5;
}

.login-input-group.error {
    border: 1px solid red;
}

.login-forgot-password {
    /* Button */
    width: 114px;
    height: 16px;

    /* Subheadline • 14pt/bold */
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */

    display: flex;
    align-items: center;
    text-align: right;

    /* Primary/Primary */
    color: #018D8A;
}

.login-form-buttons {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
}

.login-form-buttons button {
    width: 48%
}

.login-submit {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;

    /* Primary/Primary */
    background: #018D8A;
    border-radius: 8px;
    color: #FFFFFF;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}

.login-submit .spinner {
    position: absolute;
    margin-right: 84px;
}

.login-register {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 0px;

    /* Primary/Primary-04 */
    background: #F2F9F9;

    /* Primary/Primary-02 */
    border: 1px solid #80C6C4;
    border-radius: 8px;
    color: #018D8A;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
}

.link-color {
    color: #018D8A;
}

@media (max-width: 1420px) {
    .login-right-content {
        margin: 192px 80px;
    }
}

@media (max-width: 980px) {
    .login-container {
        flex-flow: wrap;
        align-content: center;
        align-items: center;
    }

    .login-right {
        width: 100%;
        overflow: hidden;
        background-image: url('/images/login-bg.png');
        background-repeat: no-repeat;
        background-size: cover;
    }
    
    .login-right-content {
        margin: 60px;
    }

    .login-welcome, .login-welcome-text, .login-forgot-password {
        color: #FFFFFF;
    }
}

@media (max-width: 420px) {
    .login-right-content {
        margin: 20px;
    }

    .login-welcome {
        margin-top: 10px;
    }

    .login-input-group {
        margin-top: 30px;
    }

    .login-form-buttons {
        margin-top: 20px;
    }
}